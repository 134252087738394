.input-disabled input{
  text-align: center !important;
}
.ant-progress-outer {
  height: 4px !important;
}
.ant-progress-bg {
  height: 4px !important;
}
.ant-checkbox-inner {
  border-radius: 25px !important;
}
.ant-checkbox-inner::after {
  border-radius: 25px !important;
}
.ant-checkbox::after {
  border-radius: 25px !important;
}
.ant-checkbox-inner:after {
  margin: 0.5px 0px 0px 0.7px !important;
  top: 57% !important;
  background: #fff !important;
  width: 7px !important;
  height: 7px !important;
}
.next-step-bgimg1 {
  background-image: url("../assets/images/home/process-1-modified.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.next-step-bgimg2 {
  background-image: url("../assets/images/home/process-2.jpg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.next-step-bgimg3 {
  background-image: url("../assets/images/home/process-3.jpg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.next-step-bgimg4 {
  background-image: url("../assets/images/home/process-4.jpg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.next-step-bgimg5 {
  background-image: url("../assets/images/home/process-5.jpg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.next-step-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #1990ff;
  border-radius: 16px;
  overflow-y: auto;
}

.next-step-boxes-main-div:hover .next-step-overlay {
  opacity: 1;
}
.next-step-boxes-main-div .next-step-overlay {
  opacity: 0;
}
.next-step-boxes-main-div:hover .opacity-overly-text {
  opacity: 1;
}
.next-step-boxes-main-div .opacity-overly-text {
  opacity: 0;
}
.next-step-boxes-main-div-responsive {
  overflow: auto;
}
.next-step-boxes-main-div-responsive:hover .next-step-overlay {
  opacity: 1;
  overflow: auto;
  padding: 0px 10px;
}

.next-step-boxes-main-div .number-box {
  transition: width 0.7s ease-in-out;
}
.next-step-boxes-main-div:hover .number-box {
  width: 50px;
}

/*  */
/* .slider-active-tooltip .ant-tooltip-inner { */
.slider-active-tooltip {
  margin-top: 12px !important;
}
.slider-active-tooltip .slider-active-tooltip-inner {
  color: #007ddf !important;
  background-color: #fff !important;
  border-radius: none !important;
  box-shadow: none !important;
  font-weight: bold !important;
  font-size: 16px !important;
}
.slider-active-tooltip .slider-active-tooltip-arrow {
  display: none !important;
}
